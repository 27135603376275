import * as React from 'react'
import Stack from '@eversports/klimt-primitives/Stack'
import Box from '@eversports/klimt-primitives/Box'
import Heading from '@eversports/klimt-primitives/Heading'
import Link from '@eversports/klimt-primitives/Link'

import { Venue } from '../../VenueProfile.types'
import SectionWrapper from '../../components/SectionWrapper'
import CorporateBadge from '../../../../components/CorporateBadge'
import { venueHasCorporatePrice } from '../../../../helpers/venue-has-corporate-price'
import FavoriteAndShareButtons from '../../components/FavoriteAndShareButtons'
import MultipleLocationsTag from '../../../../components/MultipleLocationsTag'
import { VENUE_PROFILE_BRANCHES_ID } from '../../VenueProfile.constants'

import LogoImage, { MOBILE_LOGO_DIMENSION } from './LogoImage'
import Address from './Address'
import Rating from './Rating'
import TrialOfferTags from './TrialOfferTags'

interface Props {
  venue: Venue
}

const InformationBanner = ({ venue }: Props) => {
  const hasSpecialCorporatePrice = venueHasCorporatePrice(venue.specialPriceTypes)
  const isOnline = !('address' in venue)
  return (
    <SectionWrapper
      sx={{
        gap: 3,
        display: 'grid',
        gridTemplateRows: 'repeat(4, auto)',
        gridTemplateColumns: { xs: `${MOBILE_LOGO_DIMENSION}px auto`, sm: 'max-content max-content auto' },
        // For the LogoImage
        '& :first-child': { xs: { gridRow: 'span 2' }, sm: { gridRow: 'span 4' } },
        // For the FavoriteAndShareButtons
        '> div:last-of-type': {
          display: { xs: 'none', sm: 'flex' },
          gridColumn: { xs: 'none', sm: '3 / span 1' },
          gridRow: { xs: 'none', sm: '1 / span 4' },
          flexWrap: 'wrap',
          alignSelf: 'baseline',
          justifyContent: 'flex-end',
        },
      }}
    >
      <LogoImage venueName={venue.name} venueLogoImgSrc={venue.logo?.xSmall} />
      <Heading is="h1" color="midnight" alignSelf="flex-end">
        {venue.name}
      </Heading>
      <Rating rating={venue.rating} reviewCount={venue.reviewCount} />
      <Stack direction="row" flexWrap="wrap" gap={2} sx={{ gridColumn: { xs: 'span 2', sm: 'auto' } }}>
        <Link to={`#${VENUE_PROFILE_BRANCHES_ID}`} external>
          <MultipleLocationsTag company={venue.company} isOnline={isOnline} />
        </Link>
        <TrialOfferTags tags={venue.tags} slug={venue.slug} />
        {hasSpecialCorporatePrice && <CorporateBadge canOpenModal citySlug={venue.city.slug} />}
      </Stack>
      {!venue.hideAddress && (
        <Box sx={{ gridColumn: { xs: 'span 2', sm: 'auto' } }}>
          <Address address={venue.address} />
        </Box>
      )}
      <FavoriteAndShareButtons />
    </SectionWrapper>
  )
}

export default InformationBanner
